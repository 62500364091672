import React from 'react'
import { WhatsApp } from '@mui/icons-material';

const Login = () => {

  if (Number)
    console.log(Number.length)

  const GoWhatsapp = () => {
    window.open('https://wa.me/916363773386?text=Sir%2C%20I%20Need%20Id', '_blank');
  }

  return (
    <div className='LoginBox' >


      <div className='LogoBan' ><img src="logoo.png" alt="" /></div>
      <div className='WhatsappNowBtn cp'  onClick={GoWhatsapp} >
        <div style={{ width: '10%',marginTop:'4px' }} ><WhatsApp fontSize='large' /></div>
        <div style={{ width: '100%' ,marginTop:'5px' }} className="WhatsappNowBtn-text" >Whatsapp Now</div>
      </div>
      <div className='Note ' style={{ marginTop: '20px' }}>Usually Get Id in A minute.</div>
    </div>
  )
}
export default Login