import React from 'react'
import About from './About';
import './App.css';
import Casino from './Casino';
import Preloader from './Preloader';
import Cat from './Cat';
import CatBox from './CatBox';
import Crousal from './Crousal';
import MiniCat from './MiniCat';
import Nav from './Nav';
import Popular from './Popular';
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';


const Home = () => {

    const GoWhatsapp = () => {
        window.open('https://wa.me/916363773386?text=Sir%2C%20I%20Need%20Id', '_blank');
    }

    return (
        <div>
            <Preloader />
            <Box >
                <Fab large aria-label="like"
                    onClick={GoWhatsapp}
                    sx={{
                        position: 'fixed',
                        bottom: 25,
                        right: 25,
                        height: 50,
                        width: 50,
                        zIndex: '999'

                    }}>
                    <img src="W.png" width={'60px'} alt="" />
                </Fab>
            </Box>
            <Nav />
            <Crousal />
            <div className='LogoBan' ><img src="logoo.png" alt="" /></div>
            <div className='CatBoxRow'>
                <span style={{ color: '#1E8067' }} ><EnergySavingsLeafIcon /></span>
                <div className="pdL1" >Auto withdrawal And Deposit <span className='blink' > 24x7</span> </div>
            </div>
            <MiniCat />
            <Cat />
            <CatBox />
            <Popular />
            <Casino />
            <div className='LogoBan' ><img src="logoo.png" alt="" /></div>
            <About />


        </div>
    )
}

export default Home